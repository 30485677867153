import Modal from 'react-modal'
import "./Modal.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/pro-duotone-svg-icons'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    border:'1px solid #83A645',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '75%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
  },
};

const DietModal = ({modalIsOpen, setModalOpen}) =>{

  const closeModal = () => {
    setModalOpen(false)
  }
  return (
    <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Order Instructions"
  >
    <span className="close-modal" src='/closeicon.svg' alt='close' onClick={closeModal}><FontAwesomeIcon icon={faSquareXmark} /> </span>
    <p className="modal-text"> <b>Carbohydrate Control/Diabetic</b> • This diet controls the amount of carbohydrates ordered at one time as well as the daily total of carbohydrates • The phone clerk will allow you to order a maximum of 80 grams of carbohydrate per meal, 240 grams of carbohydrates for the full day 
    <br/><br/> Patients on Insulin for Blood Sugar Control To help us manage your blood sugar, your nurse needs to check your blood sugar levels before your eat your breakfast, lunch & dinner. Additional blood sugar checks will be at 9pm & 2am to ensure your baseline blood sugar levels are well controlled. For the best blood sugar management with room service dining, please do these things: • Eat 3 times per day & do not skip meals • Order meals at consistent meal times and space meals at least 3-4 hours apart. Each mealtime insulin dose is active in your body for approximately 4 hours • Most Important: Wait for your nurse to check your blood sugar before eating your meal</p> 
    <p className="modal-text"><b>Cardiac</b> • This diet restricts the sodium and saturated fat ordered at one time, as well as the daily total • This icon [ ] indicates menu items that are lower in sodium and saturated fat • The phone clerk will allow you to order a maximum of 800mg of sodium and 4.5g of saturated fat at one time, 2500mg of sodium and 13.5g saturated fat for the full day</p>
    <p className="modal-text"><b>Renal</b> • This diet restricts the sodium and potassium ordered at one time, as well as the daily total. You can also order a maximum of 2 servings of dairy daily • The phone clerk will allow you to order a maximum of 800mg of sodium and 800mg of potassium at one time, 2500mg of sodium and 2500mg of potassium for the full day</p>
    <p className="modal-text"><b>Gestational Diabetes</b> • This diet does not allow sweets, and restricts the quantity and timing of dairy and fruit • You must order 3 meals and 3 snacks daily. Snacks are ordered at the same time as meals • The diet clerk will work with you to meet the carbohydrate goals set by your doctor</p>
  </Modal>
  )
}

export default DietModal;