import { useState , useEffect, useRef } from 'react'
// import html2canvas from 'html2canvas'
// import { jsPDF } from 'jsPDF'
import { useSearchParams } from 'react-router-dom';
import papa from 'papaparse'
import ModalComponent from './components/Modal/Modal'
import DietModal from './components/Modal/DietModal';
import axios from 'axios';
import MenuNav from './components/MenuNav/MenuNav';
import MenuOptions from "./components/MenuOptions/MenuOptions";
import Footer from './components/Footer/Footer';
import Menu from './components/Menu/Menu'
import Header from './components/Header/Header';
import PrintButton from './components/PDF/PdfExporter';
import './App.css';


function App() {
  const printRef = useRef();
  const [allMenuData, setAllMenuData ] = useState([])
  const [nutrientRestrictedContent, setNRC] = useState([])
  const [healthAndSustainabilityContent, setHASC] = useState([])
  const [foodFromHomeContent, setFFHC] = useState([]);
  const [activeMenu, setActiveMenu] = useState('')
  const [campus, setCampus] = useState('ph')
  const [diet, setDiet] = useState('all')
  const [language, setLanguage] = useState('English')
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dietModalIsOpen, setDietModalIsOpen] = useState(false);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const filterMenuItems = menuItems => {
    return menuItems.filter(item => {
      return item['Menu Section']?.toLowerCase() === activeMenu && matchCampus(item, campus)
    })
  }
  const matchCampus = (item, campus) => {
    if (item['Item Descrip'] === "menu item" || item['Item Descrip'] === "menu item bold") {
      if (campus === "mb") {
        if (diet === "fiber restricted") {
          return item['G-MB Fiber Restricted'] === "y"
        } else if (diet === "low carb") {
          return item['E-MB Low Carbohydrate Snacks (show nutritional tile)'] === "y" //TODO UPDATE WHEN NEW COLUMN IS ADDED 
        } else if(diet === "gestational diabetic"){
          return item['D-MB Snack Combos for Gestational Diabetic menus'] === "y" //TODO UPDATE WHEN NEW COLUMN IS ADDED 
        }

        return item['A-MB English Nutritional'] === "y"

      } else if (campus === "ph") {
        if (diet === "fiber restricted") {
          return item['G-PH Fiber Restricted'] === "y"
        } else if (diet === "low carb") {
          return item['E-PH Low Carbohydrate Snacks (show nutritional tile)'] === "y" //TODO UPDATE WHEN NEW COLUMN IS ADDED 
        } else if(diet === "gestational diabetic"){
          return item['D-PH Snack Combos for Gestational Diabetic menus'] === "y" //TODO UPDATE WHEN NEW COLUMN IS ADDED 
        }
        return item['A-PH English Nutrional'] === "y"
      } else if(campus === 'mz'){
          return item['A-MZ Menu'] === 'y'
      }
    }
    return true
}
  const fetchSheetsApi = async() => {
    try {
      let data = await axios.get('https://docs.google.com/spreadsheets/d/e/2PACX-1vR3f1vEcoPWb73tgxb_Po1ST-j8w13ZHJOyxk7MiIIN6mhFGvUmYo38dq1sh8fPyCc6ZkXGJz3EB4mp/pub?gid=1466203069&single=true&output=csv')
      const result = await papa.parse(data.data, {header:true})
      setAllMenuData(result.data)
    } catch(err){
      console.log(err)
    }
  }
  useEffect(()=>{
    const ffhc = []
    const hasc = []
    const nrc = []
    allMenuData.forEach(item => {
      if(item['Menu Section'] === "Food from home"){
        ffhc.push(item)
      } else if(item['Menu Section'] === "Sustainability"){
        hasc.push(item)
      } else if( item['Menu Section'] === "cover" && !item['Item descrip']){
        nrc.push(item)
      }
      setNRC(nrc)
      setHASC(hasc)
      setFFHC(ffhc)
    })
  },[allMenuData])
  useEffect(() => {
    fetchSheetsApi();    
    // fetchData();
    if(searchParams.get('menu')){
      const parsedMenu = searchParams.get('menu').split('+').join(' ').toLowerCase()
      setActiveMenu(parsedMenu);
    }
    else{
      setActiveMenu('blue bear')
    }
    if(searchParams.get('language')){
      const language = searchParams.get('language')
      setLanguage(language)
    }else{
      setLanguage('English')
    }
    if(searchParams.get('campus')){
      setCampus(searchParams.get('campus'))
    } else {
      setCampus('ph')
    }
  },[])
  useEffect(() => {
    setSearchParams({menu:activeMenu, language, campus})
  },[activeMenu, setSearchParams, language, campus])

  return (
    <div data-language={language} className="App" data-campus={campus}>
     <DietModal modalIsOpen={dietModalIsOpen} setModalOpen={setDietModalIsOpen} />
     <ModalComponent modalIsOpen={modalIsOpen} setModalOpen={setModalIsOpen}/>
     <Header/>
     <MenuOptions setLanguage={setLanguage} setCampus={setCampus} setDiet={setDiet} campus={campus} language={language} />
     <MenuNav setDietModalIsOpen={setDietModalIsOpen} setModalIsOpen={setModalIsOpen} language={language} setActiveMenu={setActiveMenu} activeMenu={activeMenu} allMenuItems={allMenuData} />
     <Menu ref={printRef} language={language} menuItems={filterMenuItems(allMenuData)} activeMenu={activeMenu}/>
     <PrintButton targetRef={printRef}/>
     <Footer language={language} content={nutrientRestrictedContent} />
     <Footer language={language} content={healthAndSustainabilityContent} />
     <Footer language={language} content={foodFromHomeContent} />
    </div>
  );
}

export default App;
