import { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas } from '@fortawesome/pro-duotone-svg-icons'
const LanguageSelect = ({setLanguage, language}) => {
  const updateLanguage = (e) => {
    setLanguage(e.target.value)
  }
  return (
    <div className="language-select nav-button">
      <FontAwesomeIcon icon={faGlobeAmericas} />
      <select value={language} onChange={(e) => {updateLanguage(e)}}>
        <option value="English">English</option>
        <option value="Spanish">Español</option>
        <option value="Russian">русский</option>
        <option value="Chinese">中文</option>    
      </select>
    </div>
  )
}

export default LanguageSelect;
