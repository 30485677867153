import './MenuItem.css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faForkKnife } from '@fortawesome/pro-thin-svg-icons';
import ReactImageAppear from 'react-image-appear'
const MenuItem = ({ item, language }) => {
  const calories = {
    English: 'Calories',
    Spanish: 'Calorías',
    Russian: 'Калории',
    Chinese: '能量',
  }
  const Protien = {
    English: 'Protein',
    Spanish: 'Proteína',
    Russian: 'Белки',
    Chinese: '蛋白质',
  }
  const Carbs = {
    English: 'Carbs',
    Spanish: 'Carbohidratos',
    Russian: 'Углеводы',
    Chinese: '碳水化合物',
  }
  const Fat = {
    English: 'Fat',
    Spanish: 'Grasa',
    Russian: 'Жиры',
    Chinese: '脂肪',
  }
  const SaturatedFat = {
    English: 'Saturated Fat',
    Spanish: 'Grasa Saturada',
    Russian: 'Жиры Сацитированные',
    Chinese: '饱和脂肪',
  }
  const Sodium = {
    English: 'Sodium',
    Spanish: 'Sodio',
    Russian: 'Натрий',
    Chinese: '钠',
  }
  const Potassium = {
    English: 'Potassium',
    Spanish: 'Potasio',
    Russian: 'Калий',
    Chinese: '钾',
  }
  const [isHovered, setHovered] = useState(false);
  const formatImage = (image) => {
    if(image){
      const url = item.Image.split('?')[0] + "?raw=1"
      return <ReactImageAppear 
      src={url}
      animation="fadeIn"
      placeholderStyle={{background:'white'}}
      animationDuration="0s"
      loader="/loading/eggloading2.svg"
      loaderStyle={{height: '100px', width: '100px', left:'35px', top:'0'}}
      />
    }
    return <FontAwesomeIcon icon={faForkKnife} />
  }
  const handleMouseEnter = () => {setHovered(true)}
  const handleMouseLeave = () => {setHovered(false)}
  const shortContent = item[language].length > 30 ? item[language].substring(0,30) + '...' : item[language];
  return (
    <div className="menu-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="menu-item__upper">
      <div className= "menu-item__number">{item['Item number']}.</div>
      <div className="menu-item__title">{isHovered ? shortContent: item[language] }</div>
      </div>
      <div className="menu-item__image"> 
        {formatImage(item.Image)}
      </div>
      <div className = "menu-item__nutrition">{
        <ul>
          <li>{calories[language]}: {item['Cal']}</li>
          <li>{Protien[language]}: {item['Prot']}</li>
          <li>{Carbs[language]}: {item['Carb']}</li>
          <li>{Fat[language]}: {item['Fat']}</li>
          <li> {SaturatedFat[language]}: {item['Sat Fat']}</li>
          <li>{Sodium[language]}: {item['Sod']}</li>
          <li> {Potassium[language]}: {item['Potas']}</li>
        </ul>
      }</div>
    </div>
  );
}

export default MenuItem;