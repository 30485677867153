import React from "react";
import CampusSelect from "./CampusSelect";
import DietSelect from "./DietSelect";
import LanguageSelect from "./LanguageSelect";
import './MenuOptions.css';
const MenuOptions  = ({setCampus, setLanguage, setDiet, campus, language}) => {
  return (
    <div className="menu-options" style={{backgroundImage:"url(/Main-salmon-header.jpg"}}>
      <div>
      <LanguageSelect language={language}setLanguage={setLanguage} />
      <DietSelect setDiet={setDiet} />
      <CampusSelect campus={campus} setCampus={setCampus} />
      </div>
      <h2>CALL 3-1111 TO PLACE ORDER</h2>
    </div>
  );
}

export default MenuOptions;