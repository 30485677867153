import Modal from 'react-modal'
import "./Modal.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/pro-duotone-svg-icons'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    border:'1px solid #83A645',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '75%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
  },
};

const ModalComponent = ({modalIsOpen, setModalOpen}) =>{

  const closeModal = () => {
    setModalOpen(false)
  }
  return (
    <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Order Instructions"
  >
    <span className="close-modal" src='/closeicon.svg' alt='close' onClick={closeModal}><FontAwesomeIcon icon={faSquareXmark} /> </span>
    <p className="modal-text">Full room service available from 7am - 8pm daily | call 3-1111 (415-353-1111) </p>
    <p className="modal-text">Please watch our 5 minute video our Patient Room Service program. For inpatients at UCSF Health Mission Bay, Parnassus, and Mount Zion.</p>
    <iframe className='modal-video' src="https://www.youtube.com/embed/4AQ1UzbefTw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <p className="modal-text" >For patients who have access to MyChart Bedside at Mount Zion 5N and Parnassus 15M, please watch our 3 minute guide on how to place meal orders from the convenience of your bedside using the Let’s Eat feature.</p>
    <iframe className='modal-video' src="https://www.youtube.com/embed/y-BLOq45Vkk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </Modal>
  )
}

export default ModalComponent;