import './Footer.css';
import { useState } from 'react';

const Footer = ({content, language}) => {
  const [open, setOpen] = useState(false);
  const title = content.find(i => {
    return i['Item Descrip'] === "Section title" || i['Item Descrip'] === "Title";
  })

  const toggle = () => setOpen(!open);
  return (
    <div className={`footer ${open ? "open" : ""}`}>
      <h3 onClick={toggle}> {title ? title[language]:""} </h3>
      <div className="footer-content">
        {content.map((item) => {
          return (
            <>
              <p key={item['English']}>{item[language]}</p> <br/>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Footer;