import './Header.css';

const Header = () => {
  return (
    <header style={{backgroundImage:'url(/Birch2.jpg)'}} className="menu-hero-header">
      <a href="https://nutrition.ucsf.edu/patient-dining" className="menu-hero-header__logo">
        <img src="/UCSF-Fresh-logo.png" alt="logo" />
      </a>
        <h1>ROOM SERVICE MENU</h1>
        <div className="menu-hero-header__divider"/>
        <div id="menu-header-undertext">
        <h2>with Nutrition Facts</h2>
        </div>       
      </header>
  )
}


export default Header;
