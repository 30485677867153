import './SectionContainer.css'
const SectionContainer = ({ children, title, ghostSection }) => {
  let style = {}
  if(ghostSection ) {
    style = {
      display: 'none',
    }
  }
  return (
    <div  className="section-container">
      <h3 className="section-container__title" style={style}>{title}</h3>
      <div className={`section-children`} > {children} </div>
    </div>
  );
}

export default SectionContainer;