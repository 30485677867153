import './PdfExporter.css';

import ReactToPrint from 'react-to-print';



const PrintButton = ( {targetRef} ) => {

  return (
    <div>
      <ReactToPrint
        trigger={() => <div style={{display:'flex', justifyContent:'flex-end'}}><button className="pdf-button">Print</button></div>}
        content={() => targetRef.current}
      />
    </div>
  );
};


export default PrintButton;