import React from 'react';

import MenuItem from "../MenuItem/MenuItem";
import SectionContainer from "../section/SectionContainer";
import './Menu.css';
const Menu = React.forwardRef(({ menuItems, language, activeMenu }, ref) => {
  const buildMenu = () => {
    const menu = [];
    let currentItems = [];
    let currentSection = null;
    const itemNumbers = new Map();
    for (let i = 0; i < menuItems.length; i++) {
      const item = menuItems[i];
      setIDX(itemNumbers, item);
      if(item['Item Descrip'] === 'menu item'|| item['Item Descrip'] === 'menu item bold'){
        currentItems.push(<MenuItem key={item['Item number']} item={item} language={language} />);
      } else if(item['Item Descrip'] === 'menu subhead'){
        if(!currentSection){
          menu.push(<SectionContainer key={i} ghostSection={true}>{currentItems}</SectionContainer>);
          currentItems = [];
          currentSection = item;
        } else{
          menu.push(<SectionContainer key={currentSection['English']} title={currentSection[language]}>{currentItems}</SectionContainer>);
          currentItems = [];
          currentSection = item;
        }
      }
      
    }
    if(currentItems && currentSection){
      menu.push(<SectionContainer key={currentSection['English']} title={currentSection[language]}>{currentItems}</SectionContainer>);
    } else if(currentItems){
      menu.push(<SectionContainer key={Math.random() * Math.random()} ghostSection={true}>{currentItems}</SectionContainer>);
    }
    return menu;
  }
  
  const imageUrls = {
    "blue bear": '/BlueBear-chocchip-header.jpg',
    'cocina verde': '/CocinaVerde-dilla-header.jpg',
    'our kitchen': '/OurKitchen-header.jpg',
    'beverages': '/Beverages-header.jpg',
    'pasta fresca': '/PastaFresca-header.jpg',
    'rice & spice': '/Rice-and-Spice-header.jpg',
    'snacks & baby food': '/SnacksBabyfood-header.jpg',
    'condiments': '/Condiments-header.jpg',
    'corner grill': '/CornerGrill-TurkeyBurg-header.jpg',
    'green fields': '/GreenFields-veggies-header.jpg',
    'sutro soup': '/SutrosSoup-header.jpg',
    'morning glory': '/MorningGlory-header.jpg',
  }
  const title = menuItems.find(item => item['Item Descrip'] === 'section title');
  const subtitle = menuItems.find(item => item['Item Descrip'] === 'section subtitle');
  return (
    <div ref={ref} className="menu">
      <div className="menu-header">
        <div className="menu-header-text" style={{backgroundImage:'url(/Birch2.jpg)'}}>
          <h3>{title ? formatTitle(title[language]) : ""}</h3>
          <h4>{subtitle? subtitle[language] : ""}</h4>
        </div>
        <div className="menu-header-image">
          <img src={imageUrls[activeMenu]} alt={activeMenu} />
        </div>
      </div>
      <div className="menu-items">
      {buildMenu()}
      </div>
    </div>
  )
})


export default Menu;

function setIDX(itemNumbers, item) {
  if (itemNumbers.get(item['Item number'])) {
    let versionNumber = itemNumbers.get(item['Item number']) + 1;
    itemNumbers.set(item['Item number'], versionNumber);
    item['Item number']?.concat(`- ${versionNumber}`);
  } else {
    itemNumbers.set(item['Item number'], 1);
  }
}

const formatTitle = title => {
  if(title.length > 30){
    let titleArr = title.split("(");
    return (
      <>
        {titleArr[0]}
        <br/>
        ({titleArr[1]}
      </>
    )
  }
  return title;
}