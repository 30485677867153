import React from 'react';
const CampusSelect = ({setCampus, campus}) => {
  const handleChange = (e) => {
    setCampus(e.target.value)
  }
  return (
    <div className="theme-select nav-button">
      <select value={campus} onChange={(e) => {handleChange(e)}}>
        <option value="ph">Parnassus</option>
        <option value="mb">Mission Bay</option>
        <option value="mz">Mount Zion</option>
      </select>
    </div>
  )
}


export default CampusSelect;