import './MenuNav.css'

const MenuNav = ({ setActiveMenu, activeMenu, language, allMenuItems, setModalIsOpen, setDietModalIsOpen}) => {
  const howToOrderTranslations = {
    "English": 'How to Order',
    "Spanish": 'Cómo pedir',
    "Russian": 'Как заказать',
    "Chinese": '如何下订单'
  }

  // Current menus, may one day refactor to be generated automatically but this works for now
  const menus = allMenuItems?.filter(item => item['Item Descrip']?.toLowerCase() === 'section title' && item['Menu Section'] !== 'Food from home' && item['Menu Section'] !== 'Sustainability') || [];
  const openModal = () => {
    setModalIsOpen(true);
  }
  const openDietModal = () => {
    setDietModalIsOpen(true);
  }
  const formatText = text => {
    if(text.includes('(')){
      const formatted =  text.split('(');
      return (
        <>
          {formatted[0]}
          <br/>
          <p className="menu-nav-subtitle">
          ({formatted[1]}
          </p>
        </>
      )
    }
    return text
  }
  return (
    
    <>
    <div className="menu-wrapper">

   
    <div className="menu-nav-header">
      <h3 onClick={openModal}>
        {howToOrderTranslations[language]}
      </h3>
      <h3 onClick={openDietModal} id="menu-nav-nutritional-info">
        SPECIAL DIET INFO
      </h3>
    </div>
    <div className="menu-nav">
      <h3 className="menu-nav-title">
        On the Menu:
      </h3>
      <div className="menu-nav-items">
        {menus.map((item, index) => {
          return (
            <div
              key={index}
              className={item['Menu Section'].toLowerCase() === activeMenu ? "menu-nav-item active-menu" : "menu-nav-item"}
              onClick={() => setActiveMenu(item['Menu Section'].toLowerCase())}
            >
              {formatText(item[language])}
            </div>
          )
        })}
      </div>
      <select value="" onChange={e => setActiveMenu(e.target.value.toLowerCase())} className="menu-nav-select">
        <option style={{display:"none"}}value="">☰</option>
        {menus.map((item, index) => {
          return (
            <option
              key={index}
              value={item['Menu Section']}
            >
              {item[language]}
            </option>
          )
        })}
      </select>
      </div>
    </div>
    </>
  )
}


export default MenuNav;