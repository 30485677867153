import { useState } from "react"
const DietSelect = ({setDiet}) => {
  const [diet, setDietState] = useState("All Menus")
  const updateDiet = (e) => {
    setDietState(e.target.value)
    setDiet(e.target.value)
  }
  return (
    <div className="diet-select nav-button">
      <select value={diet} onChange={(e) => {updateDiet(e)}}>
        <option value="all">All Menus</option>
        <option value="low carb">Low Carbohydrate</option>
        <option value="gestational diabetic">Gestational Diabetic</option>
        <option value="fiber restricted">Fiber Restricted</option>
      </select>
    </div>
  )
}

export default DietSelect;